<template>
  <div>
    <!-- <c-search-box @enter="getList">
    </c-search-box> -->
    <c-table
      ref="table"
      title="부담작업 목록"
      :columns="gridColumns"
      :data="grid.data"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :hideBottom="true"
      gridHeightAuto
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
      <!-- eslint-disable -->
      <template v-slot:customHeader="{ props, col }">
        <q-img :src="`${col.src}`"></q-img>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'worksheetPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          data: [],
          plantCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        headerItems: [],
        defaultColumns: [
          {
            name: 'a',
            field: 'a',
            label: '구분',
            style: 'width:80px',
            child: [
              {
                name: 'b',
                field: 'b',
                label: '노출시간',
                style: 'width:80px',
                child: [
                  {
                    name: 'c',
                    field: 'c',
                    label: '노출빈도',
                    style: 'width:80px',
                    child: [
                      {
                        name: 'd',
                        field: 'd',
                        label: '신체부위',
                        style: 'width:80px',
                        child: [
                          {
                            name: 'e',
                            field: 'e',
                            label: '작업자세<br/>및 내용',
                            child: [
                              {
                                name: 'f',
                                field: 'f',
                                label: '무게',
                                align: 'center',
                                sortable: false,
                                style: 'width:80px',
                              },
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ]
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      headerUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridColumns() {
      let _cols = this.$_.cloneDeep(this.grid.defaultColumns);
      if (this.grid.headerItems && this.grid.headerItems.length > 0) {
        this.$_.forEach(this.grid.headerItems, item => {
          /**
           * 1. 구분
           * 2. 노출시간
           * 3. 노출빈도
           * 4. 신체부위
           * 5. 작업자세 및 내용
           * 6. 무게
           */
          let _col = {
            name: `heavyWorkName${item.heaHeavyWorkNo}`,
            field: `heavyWorkName${item.heaHeavyWorkNo}`,
            label: item.heavyWorkName,
            style: 'width:100px',
            headerCustom: true,
            src: item.src,
            child: [
              {
                name: `exposureTime${item.heaHeavyWorkNo}`,
                field: `exposureTime${item.heaHeavyWorkNo}`,
                label: item.exposureTime,
                style: 'width:100px',
                child: [
                  {
                    name: `exopsureCnt${item.heaHeavyWorkNo}`,
                    field: `exopsureCnt${item.heaHeavyWorkNo}`,
                    label: item.exopsureCnt,
                    style: 'width:100px',
                    child: [
                      {
                        name: `bodyDesc${item.heaHeavyWorkNo}`,
                        field: `bodyDesc${item.heaHeavyWorkNo}`,
                        label: item.bodyDesc,
                        style: 'width:100px',
                        child: [
                          {
                            name: `positionDesc${item.heaHeavyWorkNo}`,
                            field: `positionDesc${item.heaHeavyWorkNo}`,
                            label: item.positionDesc,
                            style: 'width:100px',
                            child: [
                              {
                                name: item.heaHeavyWorkNo,
                                field: item.heaHeavyWorkNo,
                                label: item.weightDesc,
                                align: 'center',
                                sortable: false,
                                style: 'width:100px',
                                type: 'check',
                                'true': 'Y',
                                'false': 'N',
                              },
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ]
              },
            ]
          };
          _cols.push(_col)
        })
      }
      return _cols
    }
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.headerUrl = selectConfig.hea.heavyWork.list.url;
      // code setting
      // list setting
      this.setHeader();
      this.getList();
    },
    setHeader() {
      this.$http.url = this.headerUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
        plantCd: this.popupParam.plantCd
      };
      this.$http.request((_result) => {
        this.grid.headerItems = _result.data;
      },);
    },
    getList() {
      this.grid.data = [];
      let _data = {};
      if (this.popupParam.data && this.popupParam.data.length > 0) {
        for (let i = 1; i < 12; i++) {
          let _check = this.$_.find(this.popupParam.data, { heaHeavyWorkNo: String(i) })
          this.$set(_data, String(i), (_check ? 'Y' : 'N'));
        }
        this.grid.data.push(_data)
      } else {
        for (let i = 1; i < 12; i++) {
          this.$set(_data, String(i), 'N');
        }
        this.grid.data.push(_data)
      }
      // this.$http.url = this.listUrl;
      // this.$http.type = 'GET';
      // this.$http.param = this.searchParam;
      // this.$http.request((_result) => {
      //   this.grid.data = _result.data;
      // },);
    },
    select() {
      delete this.grid.data[0].editFlag
      delete this.grid.data[0].chgUserId
      this.$emit('closePopup',  this.$_.cloneDeep(this.grid.data));
    },
  }
};
</script>
